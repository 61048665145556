import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/global/Layout";

export default function TextPage(props) {
  const title = props.data.markdownRemark.frontmatter.title;
  const html = props.data.markdownRemark.html;
  const seoData = props.data.markdownRemark.frontmatter.seo;
  return (
      <Layout  seoData={seoData}>
      <section className="inner-page">
        <div className="container">
          <div className="col-12 col-lg-9 mx-auto">
            <h1 className="font-medium">{title}</h1>
            <div
              className="black-color"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($locale: String, $slug: String) {
    markdownRemark(
      frontmatter: {
        page: { eq: "text" }
        locale: { eq: $locale }
        slug: { eq: $slug }
      }
    ) {
      frontmatter {
        title
        description
        seo {
          title
          description
        }
      }
      html
      id
    }
  }
`;
